import classNames from 'clsx';
import * as React from 'react';
import { getDataAttributes } from '@wix/editor-elements-common-utils';
import {
  ICustomElementComponentProps,
  ICustomElementComponentChildProps,
} from '../CustomElementComponent.types';
import styles from './CustomElementComponent.scss';

const CustomElementComponent: React.FC<
  ICustomElementComponentProps
> = props => {
  const {
    id,
    tagName,
    initialAttributes,
    attributes = {},
    seoMarkup,
    isInSeo = false,
    isEnabled = true,
    events = [],
    handleEvents,
    className,
    onMouseEnter = () => {},
    onMouseLeave = () => {},
  } = props;
  const compRef = React.useRef<HTMLElement>();
  const registeredEvents = React.useRef<Record<string, boolean>>({});
  const compAttributes = React.useMemo(() => {
    try {
      return JSON.parse(initialAttributes);
    } catch (e) {
      return {};
    }
  }, [initialAttributes]);
  const childProps: ICustomElementComponentChildProps = {
    ...compAttributes,
    ...attributes,
    ref: compRef,
    suppressHydrationWarning: true,
    dangerouslySetInnerHTML: { __html: '' }, // in order for hydration not to override inner html
  };

  if (isInSeo) {
    childProps.dangerouslySetInnerHTML = { __html: seoMarkup };
  }

  React.useEffect(() => {
    if (!handleEvents) {
      return;
    }

    events
      .filter(eventName => !registeredEvents.current[eventName])
      .forEach(eventName => {
        compRef.current?.addEventListener(eventName, (e: any) => {
          handleEvents({ type: eventName, detail: e.detail });
        });
        registeredEvents.current[eventName] = true;
      });
  }, [events, handleEvents, registeredEvents]);

  if (!isEnabled) {
    return (
      <div
        id={id}
        wix-disabled-custom-element-reason="required-wix-premium-account"
      />
    );
  }

  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      className={classNames(styles.customElementContainer, className)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-testid="custom-element"
    >
      {React.createElement(tagName, childProps)}
    </div>
  );
};

export default CustomElementComponent;
