import CustomElementComponentComponent from '@wix/thunderbolt-elements/src/components/CustomElement/CustomElementComponent/viewer/CustomElementComponent';
import CustomElementComponentController from '@wix/thunderbolt-elements/src/components/CustomElement/CustomElementComponent/viewer/CustomElementComponent.controller';


const CustomElementComponent = {
  component: CustomElementComponentComponent,
  controller: CustomElementComponentController
};


export const components = {
  ['CustomElementComponent']: CustomElementComponent
};

